import type {LocationQuery, LocationQueryRaw, LocationQueryValue} from 'vue-router';
import type {ParamVerb} from '@/types/config';

const numberValues = ['series', 'year', 'month', 'limit', 'offset', 'page'];
const textValues = ['s', 'order', 'dir'];

export function customParseQuery(query: string): LocationQuery {
  const items = query.split('&').map((item) => {
    const [name, value] = item.split(/=/);
    return {name, value};
  });

  const params: LocationQuery = {};
  items.forEach(({name, value}) => {
    if (!name || name === 'EMBEDDED') {
      return;
    }
    // Text values.
    if (textValues.includes(name)) {
      params[name] = value;
      return;
    }
    // Preacher array.
    if (name === 'preacher' || name === 'denomination') {
      params[name] = value.split(',').map((item) => {
        return parseInt(item) as unknown as LocationQueryValue;
      });
      return;
    }
    // Media array.
    if (name === 'media') {
      params[name] = value.split(',');
      return;
    }
    // Number values.
    if (numberValues.includes(name)) {
      params[name] = parseInt(value) as unknown as LocationQueryValue;
      return;
    }
    // Verb values.
    const verbMatch = name.match(/verb\[(book|chapter|verse|in_text)]/);
    if (verbMatch) {
      // @ts-ignore
      params.verb = {
        ...((params.verb as unknown as ParamVerb) || {}),
        [verbMatch[1]]: parseInt(value),
      };
      return;
    }
    // Warning
    console.warn(`Unknonw param: "${name}"`, {name, value});
  });
  if (params.verb && !(params.verb as unknown as ParamVerb)?.book) {
    delete params.verb;
  }
  if (params.verb && !(params.verb as unknown as ParamVerb)?.chapter) {
    delete (params.verb as unknown as ParamVerb).chapter;
    delete (params.verb as unknown as ParamVerb).verse;
  }
  return params;
}

export function customStringifyQuery(query: LocationQueryRaw): string {
  const items = [];
  if (query.s) {
    items.push('s=' + encodeURI(query.s as string));
  }
  if (query.preacher && Array.isArray(query.preacher)) {
    items.push('preacher=' + (query.preacher as string[]).join(','));
  }
  if (query.denomination && Array.isArray(query.denomination)) {
    items.push('denomination=' + (query.denomination as string[]).join(','));
  }
  if (query.tags) {
    items.push('tags=' + parseInt(query.tags as string));
  }
  if (query.series) {
    items.push('series=' + parseInt(query.series as string));
  }
  if (query.year) {
    items.push('year=' + parseInt(query.year as string));
  }
  if (query.month) {
    items.push('month=' + parseInt(query.month as string));
  }

  if (query.media && Array.isArray(query.media) && query.media.length) {
    items.push('media=' + query.media.join(','));
  }

  if ((query.verb as unknown as ParamVerb)?.book) {
    const verb = query.verb as unknown as ParamVerb;
    items.push('verb[book]=' + verb.book);
    if (verb.chapter) {
      items.push('verb[chapter]=' + verb.chapter);
      if (verb.verse) {
        items.push('verb[verse]=' + verb.verse);
      }
    }
    if (verb.lesson) {
      items.push('verb[lesson]=1');
    }
    if (verb.in_text) {
      items.push('verb[in_text]=1');
    }
  }
  if (query.order && query.order !== 'DEFAULT') {
    items.push('order=' + encodeURI(query.order as string));
  }
  if (query.dir && (query.dir as string).toUpperCase() === 'DESC') {
    items.push('dir=DESC');
  }

  if (query.limit && parseInt(query.limit as string) > 0) {
    items.push('limit=' + parseInt(query.limit as string));
  }
  if (query.page && parseInt(query.page as string) > 0) {
    items.push('page=' + parseInt(query.page as string));
  }

  return items.join('&');
}
