import {provide} from 'vue';
import {useHead} from 'unhead';
import {isEmbedded} from '@/helpers/embedded';

import type {Configuration} from '@/types/config';
import type {SiteItem} from '@/types/sites';
import type {TextNode} from '@/types/node';

import ApiConnection from '@/api';

export async function initialFetch() {
  if (isEmbedded()) {
    ApiConnection.rest.setEmbedded(true);
  }
  // Fetch token before everything else.
  await ApiConnection.fetchToken();
  // When we have token do the rest.
  let suggestion: TextNode | null = null;
  const res = await Promise.all([ApiConnection.getConfig(), ApiConnection.getSites()]);
  const config: Configuration = res[0];
  if (isEmbedded()) {
    config.site.embedded_site = true;
  }

  const sites: SiteItem[] = res[1];
  if (config.site.daily_suggestion) {
    suggestion = await ApiConnection.getSuggestion();
  }

  return {
    config,
    sites,
    suggestion,
  };
}

export function doProvide(config: Configuration, sites: SiteItem[]) {
  provide<Configuration>('LELKESZ.config', config);
  provide<boolean>('LELKESZ.isDefaultSite', config.site.default_site ?? false);
  provide<SiteItem[]>('LELKESZ.sites', sites);
}

export function beforeAppMount(config: Configuration) {
  const favicon = document.createElement('link');
  favicon.rel = 'icon';
  favicon.href = config.site.favicon?.url ?? '/favicon.ico';
  document.head.appendChild(favicon);

  useHead({
    htmlAttrs: {
      lang: 'hu',
    },
    meta: [
      {
        name: 'og:image',
        content: config.site.og_cover ?? location.origin + '/og_lelkesz.png',
      },
      {
        name: 'description',
        content: config.site.title,
      },
    ],
  });
}
