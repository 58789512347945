<script setup lang="ts">
import {onBeforeMount, defineAsyncComponent} from 'vue';
import {RouterView} from 'vue-router';

import AppFooter from '@/components/app/AppFooter.vue';
import AppHeader from '@/components/app/AppHeader.vue';

import {doProvide, initialFetch, beforeAppMount} from '@/helpers/app';
import {redirectTo} from '@/helpers/embedded';

const MediaPlayer = defineAsyncComponent(() => import('@/components/app/MediaPlayer.vue'));
const FeedbackModal = defineAsyncComponent(() => import('@/components/app/FeedbackModal.vue'));

const {config, sites, suggestion} = await initialFetch();

doProvide(config, sites);

onBeforeMount(() => {
  if (config.default_site) {
    redirectTo(config.default_site);
    return;
  }
  beforeAppMount(config);
});
</script>
<template>
  <AppHeader
    v-if="!config.site.embedded_site"
    :config="config"
    :suggestion="suggestion"
  />
  <div
    class="site-content"
    :data-embedded-site="config.site.embedded_site"
  >
    <main class="site-content--content content">
      <RouterView />
    </main>
  </div>
  <template v-if="!config.site.embedded_site">
    <MediaPlayer :default-site="config.site.default_site" />
    <FeedbackModal
      v-if="config.site.privacy_policy"
      :privacy-policy="config.site.privacy_policy"
    />
  </template>
  <AppFooter
    :config="config"
    :sites="sites"
  />
</template>
